/* Fix the table layout so it doesn't exceed the width of its container */
.c-LinksList table {
    table-layout: fixed !important;
}
/* Remove horizontal marign from table */
.c-LinksList .ant-table-body {
    margin: 0px !important;
}
/* Remove table border and border radius */
.c-LinksList .ant-table-small {
    border: 0px;
    border-radius: 0px;
}
.c-LinksList .ant-table-thead > tr:first-child > th:first-child,
.c-LinksList .ant-table-thead > tr:first-child > th:last-child {
    border-radius: 0px;
}

/*
*   Remove the table header background color and bottom border, increase space
*   between header and first row
*/
.c-LinksList .ant-table-thead th {
    background-color: transparent !important;
    border-bottom: 0px !important;
    padding-bottom: 5px !important;
}

/* Remove row background-change-on-hover delay */
.c-LinksList .ant-table-thead > tr,
.ant-table-tbody > tr,
.c-LinksList .ant-table-tbody > tr > td {
    transition: all 0s !important;
}

/* Give space below */
.c-LinksList {
    margin-bottom: 16px;
}

/* Make link occupy all cell, justify its content */
.c-LinksList-item {
    padding: 0px !important;
}
.c-LinksList-item a {
    width: 100%;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--grey-8);
    cursor: pointer;
}

/* Make link light up on hover and on link active */
.c-LinksList-item a,
.c-LinksList-item a:focus,
.c-LinksList-item a:hover {
    text-decoration: none;
}
.c-LinksList-item a.active {
    background: var(--blue-1);
}

.c-LinksList-item a:hover {
    background: var(--blue-1);
}

/* Show the right arrow on hover and on link active */
.c-LinksList-item .c-LinksList-item-arrow {
    visibility: hidden;
}
.c-LinksList-item a:hover .c-LinksList-item-arrow,
.c-LinksList-item a.active .c-LinksList-item-arrow {
    visibility: visible;
}

/* Truncate description text */
.c-LinksList-item-description {
    width: 90%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

/* Hide the no-data placeholder */
.c-LinksList .ant-table-placeholder {
    display: none;
}
